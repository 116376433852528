<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  data() {
    return {
    };
  },
  mounted(){
 
  },
  methods:{


  },

};
</script>

<style >
html,body {
  /* background-color: #ebeff2; */
  width: 100%;
  height: 100%;
   box-sizing: border-box;
  overflow-x: hidden;
  overflow-y: scroll
 
}
       ::-webkit-scrollbar {
     width: 0 !important;
   }
   ::-webkit-scrollbar {
     width: 0 !important;height: 0;
   }
.translated-ltr {
  width: 100%;
  height: 100%;
  background-color: #ebeff2;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  /* background-color: #ebeff2; */
   width: 100%;
  height: 100%;

}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
