 import CryptoJS from 'crypto-js'
  
 //加密
 export function encrypt(str) {
  let key = CryptoJS.enc.Utf8.parse('kaoyantong123456'); // 密钥
  let iv = CryptoJS.enc.Utf8.parse('0123456789ABCDEF'); // 偏移量

  let srcs = CryptoJS.enc.Utf8.parse(str);
  var encrypted = CryptoJS.AES.encrypt(srcs, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7, 
  });

  // 需要返回base64格式的加密结果
  return CryptoJS.enc.Base64.stringify(encrypted.ciphertext);
}
  


// if(已登录) {
//   if(购买) {
//     直接观看
//   } else {
//     if(试看课程) {
//       if(普通用户试看) {
//            直接观看
//       } else if(会员试看) {
//           if(会员) {
//             直接观看
//           } else {
//             进入购买
//           }
//       }
//     } else  {
//       if(免费课程) {
//         if(普通用户免费) {
//           直接报名-->页面刷新-->观看
//         } else if(会员免费) {
//           if(会员) {
//             直接报名-->页面刷新-->观看
//           } else {
//             跳转购买-->生成订单-->购买成功-->返回详情页面观看
//           }
//         }
        
//       } else {
//         跳转购买-->生成订单-->购买成功-->返回详情页面观看
//       }
//     }
//   }
// }else{
//   跳转登录-->回到课程详情-->走if重新判断
// }