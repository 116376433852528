import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/xin.vue')
  },
  //中青
  {
    path: '/zhongqing',
    name: 'zhongqing',
    component: () => import('../views/zhongqing.vue')
  },
    //购课
    {
      path: '/buyclassh5',
      name: 'buyclassh5',
      component: () => import('../views/buyclassh5.vue')
    },
   //测试
   {
    path: '/xin1',
    name: 'xin1',
    component: () => import('../views/xin1.vue')
  },
   //新h5 指引到浏览器阿打开
   {
    path: '/newh5',
    name: 'newh5',
    component: () => import('../views/new_h5.vue')
  },
  // 研训
  {
    path: '/notice',
    name: 'notice',
    component: () => import('@/views/course/notice')
  },
  // 隐私协议
  {
    path: '/privacy',
    name: 'Privacy',
    component: () => import('@/views/privacy/index')
  },
  // 用户协议
  {
    path: '/agreement',
    name: 'Agreement',
    component: () => import('@/views/agreement/index')
  },
  // 登录
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/login')
  },
  // 落地页
  {
    path: '/LandingPage',
    name: 'LandingPage',
    component: () => import('@/views/LandingPage/index')
  },
  // 百家云课程
  {
    path: '/openCourse',
    name: 'openCourse',
    component: () => import('@/views/course/openCourse')
  },
  // 云犀课程
  {
    path: '/liveCourse',
    name: 'liveCourse',
    component: () => import('@/views/course/liveCourse')
  },
  // 跟学团
  {
    path: '/followCourse',
    name: 'followCourse',
    component: () => import('@/views/course/followCourse')
  },
  // 录播课
  {
    path: '/recordingCourse',
    name: 'recordingCourse',
    component: () => import('@/views/course/recordingCourse')
  },

  // 下载
  {
    path: '/download',
    name: 'download',
    component: () => import('@/components/common/download')
  },


  // 付款
  {
    path: '/payment',
    name: 'Payment',
    component: () => import('@/views/Payment/Payment')
  },
  {
    path: '/ali',
    name: 'ali',
    component: () => import('@/views/Payment/ali')
  },
  // 付款成功
  {
    path: '/success',
    name: 'success',
    component: () => import('@/views/Payment/success')
  },
  // 付款失败
  {
    path: '/fail',
    name: 'fail',
    component: () => import('@/views/Payment/fail')
  },
  // 优惠券选择
  {
    path: '/Coupon',
    name: 'Coupon',
    component: () => import('@/views/Coupon/Coupon')
  },
  // 拼团
  {
    path: '/GroupWork',
    name: 'GroupWork',
    component: () => import('@/views/GroupWork/index')
  },
  // 联报活动
  {
    path: '/JointReport',
    name: 'JointReport',
    component: () => import('@/views/JointReport/index')
  },  
  // 裂变活动
  {
    path: '/fission',
    name: 'fission',
    component: () => import('@/views/activity/fission')
  },  
  // 临时活动
  {
    path: '/temporary',
    name: 'temporary',
    component: () => import('@/views/temporary/index')
  },  
   // 邀请函
   {
    path: '/invitation',
    name: 'invitation',
    component: () => import('@/views/activity/invitation')
  }, 
  // 入驻须知
  {
    path: '/Settled/Notice',
    name: 'SettledNotice',
    component: () => import('@/views/Settled/Notice')
  }, 
  // 入驻协议
  {
    path: '/Settled/agreement',
    name: 'Settledagreement',
    component: () => import('@/views/Settled/agreement')
  }, 
  // 公众号绑定
  {
    path: '/officialAccount/index',
    name: 'officialAccount',
    component: () => import('@/views/officialAccount/index')
  }, 
  // 公众号消息详情
  {
    path: '/notice/index',
    name: 'notice',
    component: () => import('@/views/notice/index')
  },
    // 公众号消息详情
    // {
    //   path: '/xin',
    //   name: 'xin',
    //   component: () => import('@/views/xin.vue')
    // }, 
  

]

const router = new VueRouter({
  routes
})

export default router